<template>
  <Card no-shadow>
  <template v-slot:content>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :id="`input-group-difficulty`"
          :label="$t('ACTIONS.search')"
          class="mb-4"
        >
          <b-form-input
            v-model="localParams.search"
            :options="options.difficulty"
            text-field="label"
            value-field="value"
            name="search"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="6" md="4" xl="12">
        <b-form-group
          :id="`input-group-difficulty`"
          :label="$t('PILLS.difficulty')"
          class="mb-4"
          v-if="type === 'pills'"
        >
          <b-form-checkbox-group
            v-model="localParams.difficulty"
            :options="options.difficulty"
            text-field="label"
            value-field="value"
            name="difficulty"
            size="md"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="4" xl="12" v-if="type === 'pills'">
        <b-form-group
          :id="`input-group-type`"
          :label="$t('PILLS.type')"
          label-for="input-1"
          class="mb-4"
        >
          <b-form-checkbox-group
            v-model="localParams.type"
            :options="options.type"
            text-field="label"
            value-field="value"
            name="type"
            size="md"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="4" xl="12" >
        <b-form-group
          :id="`input-group-status`"
          :label="$t('PILLS.status')"
          v-if="type === 'pills'"
        >
          <b-form-checkbox-group
            v-model="localParams.status"
            :options="options.status"
            text-field="label"
            value-field="value"
            name="status"
            size="md"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6" lg="12" >
        <b-form-group
          :id="`input-group-status`"
          :label="'Work roles'"
          v-if="type === 'pills'"
        >
          <multiselect v-model="localParams.wrn"
            value="value"
            label="text"
            track-by="text"
            :placeholder="''"
            :multiple="true"
            class="clickable"
            :closeOnSelect="false"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
          :options="workRoles"></multiselect>
        </b-form-group>
      </b-col>
    </b-row>
  </template>
</Card>
</template>

<script>
import { mapGetters } from 'vuex'
import { DIFFICULTIES, PILL_STATUS, PILL_TYPES } from '@/config/constants'
import mapper from '@/services/mixins/mapper.mixins'

export default {
  name: 'AcademyFilters',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  model: {
    prop: 'params'
  },
  mixins: [mapper],
  data () {
    return {
      value: [],
      localParams: {
        search: '',
        type: [],
        difficulty: [],
        status: [],
        wrn: []
      },
      options: {
        type: [
          { label: this.getPillTypeDisplayName(PILL_TYPES.THEORY), value: PILL_TYPES.THEORY },
          { label: this.getPillTypeDisplayName(PILL_TYPES.PRACTICE), value: PILL_TYPES.PRACTICE }
        ],
        status: [
          { label: this.getPillStatusDisplayName(PILL_STATUS.PENDING), value: PILL_STATUS.PENDING },
          { label: this.getPillStatusDisplayName(PILL_STATUS.IN_PROGRESS), value: PILL_STATUS.IN_PROGRESS },
          { label: this.getPillStatusDisplayName(PILL_STATUS.COMPLETE), value: PILL_STATUS.COMPLETE }
        ],
        difficulty: [
          { label: this.getDifficultyDisplayName(DIFFICULTIES.VERY_LOW), value: DIFFICULTIES.VERY_LOW },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.LOW), value: DIFFICULTIES.LOW },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.MEDIUM), value: DIFFICULTIES.MEDIUM },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.HARD), value: DIFFICULTIES.HARD },
          { label: this.getDifficultyDisplayName(DIFFICULTIES.EXTREME), value: DIFFICULTIES.EXTREME }
        ]
      }
    }
  },
  watch: {
    params: {
      deep: true,
      handler (val) {
        this.localParams = val
      }
    },
    localParams: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  computed: {
    ...mapGetters({
      workRoles: 'staticInfo/getWorkRolesNice'
    })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
