<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    name="Academia"
    description=""
    :progress="0"
    style="display: none;"
    />

    <div class="text-center mt-5">
      <b-form-group label="">
        <b-form-radio-group
          id="btn-radios-2"
          v-model="selected"
          :options="sections"
          buttons
          button-variant="outline-primary"
          size="lg"
          name="radio-btn-outline"
        ></b-form-radio-group>
      </b-form-group>
    </div>

    <b-row class="main-container px-5">
      <b-col xl="2" lg="12" md="12" class="mb-3 mt-4">
        <AcademyFilters v-model="params" :type="selected === '0' ? 'courses' : 'pills'" />
      </b-col>
      <b-col xl="10" lg="12" md="12">
        <b-row class="w-100" v-if="(isCoursesLoading || courses.length > 0) && selected === '0'">

          <!-- SKELETONS LOADING -->
          <b-col class="mb-3" cols="12" v-if="isCoursesLoading">
            <b-row>
              <b-col class="mb-3" sm="12" md="6" lg="4" v-for="n in Array(12)" v-bind:key="n">
                <CategoryCardSkeleton />
              </b-col>
            </b-row>
          </b-col>
          <!-- END SKELETONS LOADING -->

          <b-col class="mb-3" sm="12" md="6" lg="4"
            v-for="content in filteredCourses"
              v-bind:key="content.id">
            <CategoryCard
              :img="getUrlImg(content.image)"
              :title="content.name"
              :text="content.description"
              :progress="calculateProgress(content.score, content.total_points)"
              :to="{ name: linkDestination, params: { id: content.id, name: content.name } }"
            >
            </CategoryCard>
          </b-col>
        </b-row>
        <b-row class="px-lg-5" v-else-if="pills.length > 0 && selected === '1'">
          <b-col cols="12">
            <PillsTable :items="filteredPills" />
          </b-col>
        </b-row>
        <NoResults v-show="!isLoading" v-else />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AcademyFilters from '@/components/AcademyFilters'

import CategoryCard from '@/components/utils/cards/CategoryCard'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'
import SectionHeader from '@/components/SectionHeader'
import NoResults from '@/components/utils/NoResults'

import PillsTable from '@/components/tables/Pills'

import mapper from '@/services/mixins/mapper.mixins'
import ProgressMixin from '@/services/mixins/progress.mixins'

import { PILL_STATUS } from '@/config/constants'

import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'Home',
  mixins: [ProgressMixin, mapper, i18nmixin],
  components: { CategoryCard, SectionHeader, AcademyFilters, CategoryCardSkeleton, NoResults, PillsTable },
  data () {
    return {
      showFirst: true,
      showSecond: false,
      selected: '0',
      sections: [
        { text: this.$t('TERMS.courses'), value: '0', show: true },
        { text: this.$t('TERMS.pills'), value: '1', show: false }
      ],
      params: {
        type: [],
        difficulty: [],
        status: []
      }
    }
  },
  watch: {
    params: {
      deep: true,
      handler () {
        this.getFilteredPills()
      }

    }
  },
  methods: {
    ...mapActions({
      getCourses: 'academy/getCourses',
      getPills: 'pills/getPills'
    }),
    toggleSection (index) {
      if (this.sections[index].show) {
        this.sections[index].show = true
      }
    },
    getPillStatus (pill) {
      if (typeof pill.student_pill_completion === 'undefined') return PILL_STATUS.PENDING

      return pill.student_pill_completion.completion_status
    },
    getFilteredPills () {
      const localParams = Object.assign({}, this.params)

      if (typeof localParams.wrn !== 'undefined') {
        localParams.wrn = localParams.wrn.map(item => item.value)
      }

      return this.getPills(localParams)
    }
  },
  computed: {
    ...mapGetters({
      courses: 'academy/getCourses',
      isCoursesLoading: 'academy/isLoading',
      isPillsLoading: 'pills/isLoading',
      pills: 'pills/getPills'
    }),
    isLoading () { return this.isCoursesLoading || this.isPillsLoading },
    linkDestination () { return this.selected === '0' ? 'courseDetail' : 'courseDetail' },
    hasCourses () { return this.courses.length > 0 },
    hasPills () { return this.pills.length > 0 },
    isSearching () { return typeof this.params.search !== 'undefined' && this.params.search.length > 0 },
    isFilteringByType () { return typeof this.params.type !== 'undefined' && this.params.type.length > 0 },
    isFilteringByDifficulty () { return typeof this.params.difficulty !== 'undefined' && this.params.difficulty.length > 0 },
    isFilteringByStatus () { return typeof this.params.status !== 'undefined' && this.params.status.length > 0 },
    translatedPills () { return this.translateObjectArray(this.pills) },
    filteredPills () {
      return this.translatedPills.filter(pill => {
        return (!this.isFilteringByType || this.params.type.indexOf(pill.type) !== -1) &&
        (!this.isFilteringByDifficulty || this.params.difficulty.indexOf(pill.difficulty) !== -1) &&
        (!this.isFilteringByStatus || this.params.status.indexOf(this.getPillStatus(pill)) !== -1) &&
        (!this.isSearching || pill.name.toLowerCase().includes(this.params.search.toLowerCase()))
      })
    },
    translatedCourses () { return this.translateObjectArray(this.courses) },
    filteredCourses () {
      return this.translatedCourses.filter(course => {
        return (!this.isSearching || course.name.toLowerCase().includes(this.params.search.toLowerCase()))
      })
    }
  },
  mounted () {
    Promise.all([this.getCourses(), this.getFilteredPills()])
      .then(_ => {
        if (typeof this.$router.currentRoute.query.show !== 'undefined') {
          if (this.$router.currentRoute.query.show === 'pills') {
            this.selected = '1'
          }
        } else {
          // En caso de tener cursos y no carreras técnicas nos movemos a la segunda pestaña
          if (this.hasPills && !this.hasCourses) this.selected = '1'
        }
      })
  }
}
</script>
